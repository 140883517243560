import { Box, Typography } from "@mui/material";
import TargetURL from "../elements/TargetURL";
import { useMemo } from "react";
import { diff } from "./tmp.js";
import PlainURL from "../elements/PlainURL.js";
import { UrlFragment } from "../../base/typography.js";

export default function DifferenceFailure({answer, target, ...props}) {

    // TODO to be smart we should check if the answer ends by TLD and decide whether to show & compare the TLD or not

    const diff2 = useMemo(() => diff.diff(answer, target.domainTLD), [answer, target]);

    return (
        <Box>
            
            <Typography>You answered:</Typography>
            <PlainURL url={answer} />
            <br />

            <Typography>But the link will take you to a different website:</Typography>
            <Typography>
            {
                diff2.changes.map((_d, index) => {
                    switch (_d.diff) {
                        case "=":
                            return <UrlFragment key={index}>{_d.char}</UrlFragment>
                        case "+":
                            return <UrlFragment key={index} style={{color: 'red'}}>{_d.char}</UrlFragment>
                        case "-":
                            return <UrlFragment key={index} style={{color: 'red', textDecoration: "line-through"}}>{_d.char}</UrlFragment>
                        default:
                            // diff is an object like {"replacedBy": "x"}
                            return <UrlFragment key={index} style={{color: 'red', textDecoration: "red wavy underline"}}>{_d.diff.replacedBy}</UrlFragment>
                    }
                })
            }
            </Typography>
            <br />

            <Typography>Are you sure the link is taking you where you think? If you are sure, you can proceed. Otherwise, you can go back and check the email again, or report it to IT.</Typography>
        </Box>
    )
}