import React from 'react';
import { Box } from '@mui/material';
import Dot from './Dot';
import { formatURL } from "../../../utils";
import { UrlFragment } from '../../base/typography';


export default function PlainURL({ url, color=true, ...props }) {

    // URL is a string for this fn

    const urlParts = url.split('.');

    return (
        <Box className="attention-tutorial-address challenge-url" sx={{ wordBreak: 'break-all' }}>
            {urlParts.map((value, index) => (
                <>
                    <UrlFragment key={index} >
                        {value}
                    </UrlFragment>
                    {index !== urlParts.length - 1 ? <Dot /> : <></>}
                </>
            ))}
        </Box>
    );
}
