import { Typography, Container, List, ListItem, Paper } from '@mui/material'
import { Subtitle, Title } from '../components/base/typography';
import { useLocalStorage } from '@uidotdev/usehooks';
import { BACKEND_ADDRESS } from '../App';

import Consent from '../components/framework/Consent';
import { baseExperimentStatus, Pages } from '../Types';

export default function ConsentShort () {

    const [participant, setParticipant] = useLocalStorage("participant", {});
    const [experiment, setExperiment] = useLocalStorage("experimentStatus", baseExperimentStatus);

    const handleClickConfirm = (event) => {

        fetch(BACKEND_ADDRESS + "/users", { method: 'POST', headers: { 'Content-type': 'application/json' }, body: JSON.stringify({
            "id": participant.platformId ? participant.platformId : "NOID",
        }) })
            .then(async (res) => {
                let res_json = await res.json();
                setParticipant(res_json);
                setExperiment({ ...experiment, page: Pages.PRE_QUESTIONNAIRE })
            })
            .catch((err) => console.log(err));

    };

    return (
        <Container>

            <Title>
                Email Clients Usability Study
            </Title>

            <Typography pb={2}>
                We are a team of researchers from <a href='https://ethz.ch'>ETH Zurich</a>, a public research university in Switzerland, investigating how people interact with email clients and online safety.
                We would like to ask you if you are willing to participate in our project on the usability of a novel interface.
            </Typography>

            <Typography pb={2}>
                You will be <b>asked to imagine you are an employee working at a fictitious company</b> and have a fixed amount of time to <b>work through the employee's email inbox</b>, handling as many emails as possible.
                You will be guided through your tasks with a tutorial.
            </Typography>

            <Typography pb={6}>
                We will record your answers to the questionnaires, and your performance during the study. No personally identifiable information about you is recorded, and no data will be published besides aggregated statistics.
                <b>You can read the full consent form at <a href='./consent-full' target='_blank'>this link</a>.</b>
            </Typography>

            <Typography>
                You can contact the research team by writing to Daniele Lain, ETH Zurich (<a href='mailto:daniele.lain@inf.ethz.ch'>daniele.lain@inf.ethz.ch</a>)
            </Typography>
            <Typography>
                The Data Protection Officer of ETH Zurich is Tomislav Mitar (<a href='mailto:tomislav.mitar@sl.ethz.ch'>tomislav.mitar@sl.ethz.ch</a>)
            </Typography>
            <Typography pb={2}>
                This project was approved by the ETH Zurich ethics committee as EK-2023-N-204.
            </Typography>
            <Typography pb={4}>
                The secretariat of the ETH Zurich Ethics Committee is available to help you with complaints in connection with your participation. Contact: <a href='mailto:ethics@sl.ethz.ch'>ethics@sl.ethz.ch</a> or <a href='tel:0041446328572'>+41 44 632 85 72</a>.
            </Typography>



            <Paper elevation={3} sx={{ padding: "1em 4em 2em", backgroundColor: "#F8F8F8" }}>
            <Subtitle>
                Consent Form
            </Subtitle>

            <Typography paddingBottom={2}>
                I, the participant, confirm by checking the box and submitting the form that:
            </Typography>

            <List dense>
                <ListItem key={1}>
                    • I have read and understood the &nbsp; <a href='./consent-full' target='_blank'>full study information</a>.
                </ListItem>
                <ListItem key={2}>
                    • I comply with the inclusion and exclusion criteria for participation described above. I am aware of the requirements and restrictions to be observed during the study.
                </ListItem>
                <ListItem key={3}>
                    • I have had enough time to decide about my participation.
                </ListItem>
                <ListItem key={4}>
                    • I participate in this study voluntarily and consent that my data be used as described above.
                </ListItem>
                <ListItem key={5}>
                    • I understand that I can stop participating at any moment.
                </ListItem>
            </List>

            <Consent consentHandler={handleClickConfirm} />
            </Paper>
        </Container>
    );
}