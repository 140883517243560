import { Typography, Container, Skeleton } from '@mui/material';
import { Preformatted, Title } from '../components/base/typography';
import { useEffect, useMemo } from 'react';
import { BACKEND_ADDRESS } from '../App';
import { useLocalStorage } from '@uidotdev/usehooks';

export default function Reward() {

    const [participant, setParticipant] = useLocalStorage("participant", {});
    const [voucher, setVoucher] = useLocalStorage("voucher", "");
    
    const voucherHref = useMemo(() => {
        return `https://app.prolific.com/submissions/complete?cc=${voucher}`;
    }, [voucher]);

    useEffect(() => {
        if (!voucher) {

            fetch(BACKEND_ADDRESS + `/users/${participant._id}/voucher`,
                {
                    method: 'GET',
                    headers: { 'Content-type': 'application/json' },
                })
                .then(async (res) => {
                    const res_json = await res.json();
                    setVoucher(res_json.voucher);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, []);

    return (
        <Container>
            <Title>End of study - Your compensation</Title>
            <Typography paddingBottom={2}>Thanks for taking part in our study of the usability of URL protection mechanisms for email clients!</Typography>
            <Typography paddingBottom={2}>Your personal code to paste back on Prolific to redeem your reward is:</Typography>
            { voucher ?
                <Preformatted>{voucher}</Preformatted>
                :
                <Skeleton />
            }
            <Typography paddingTop={2}>Or simply click here: <a href={voucherHref}>GO BACK TO PROLIFIC AND SUBMIT</a>.</Typography>
        </Container>
    );
}
