import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Title = styled('h1', {
    shouldForwardProp: (prop) => prop !== 'gutterBottom',
})(({ gutterBottom, theme }) => ({
    ...theme.typography.h1,
    fontSize: '2rem',
    element: 'h1',
    ...(!gutterBottom && {
        marginBottom: '3rem'
    }),
}));

export const Subtitle = styled('h2')(({ theme }) => ({
    ...theme.typography.h2,
    fontSize: '1.5rem',
    paddingBottom: '1rem',
    color: theme.palette.grey[700],
}));

export const MailSender = styled('h3', {
    shouldForwardProp: (prop) => prop !== 'unread',
})(({ unread, theme }) => ({
    ...theme.typography.h3,
    fontSize: '1rem',
    margin: '0 !important',
    paddingTop: '0.3rem',
    ...((unread) && {
        fontWeight: 'bold',
    })
}));

export const MailSubject = styled('h4', {
    shouldForwardProp: (prop) => prop !== 'unread',
})(({ unread, theme }) => ({
    ...theme.typography.h5,
    fontSize: '0.7rem',
    margin: '0 !important',
    paddingTop: '0.3rem',
    width: '100%',
    fontWeight: 'bold',
    ...((unread) && {
        color: '#0078D7',
    })
}));

export const MailTimestamp = styled('h4', {
    shouldForwardProp: (prop) => prop !== 'unread',
})(({ unread, theme }) => ({
    ...theme.typography.h4,
    fontSize: '0.7rem',
    margin: '0 !important',
    paddingTop: '0.3rem',
    textAlign: 'right',
    ...((unread) && {
        fontWeight: 'bold',
        color: '#0078D7',
    })
}));

export const MailPreview = styled('h5', {
    shouldForwardProp: (prop) => prop !== 'unread',
})(({ unread, theme }) => ({
    ...theme.typography.h5,
    fontSize: '0.7rem',
    margin: '0 !important',
    padding: '0.3rem 0',
    width: '100%',
    ...((unread) && {
        fontWeight: 'bold'
    })
}));

export const Preformatted = ({ ...props }) => {
    return <span><code className='preformatted'>{props.children}</code></span>
};

export const Monospace = ({ ...props }) => {
    return <span><code className='monospace'>{props.children}</code></span>
};

export const EmailHeader = styled(Typography)(({ theme }) => ({
    ...theme.typography,
    color: theme.palette.text.secondary,
    fontSize: '0.8rem',
    paddingBottom: '0.1rem',
}));

export const UrlFragment = styled(Typography)({
    wordBreak: 'break-all',
    paddingBottom: 2,
    textAlign: 'center',
    display: 'inline',
    fontSize: '1.4rem',
    fontFamily: 'monospace',
});
