import { BACKEND_ADDRESS } from "./App";

export async function logEvent(userId, log_string, data) {

    fetch(BACKEND_ADDRESS + "/log", {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({ 'participant_id': userId, 'log_string': log_string, 'extra_info': data })
    })
        .then(async (res) => await res.json())
        .catch((err) => console.log(err));

}
