import React from 'react';
import { Container } from "@mui/material"

export default function QuestionnaireGroup(props) {
    return (
        <Container className='questionnaire-group'>
            {React.Children.map(props.children, child => {
                return React.isValidElement(child) ? React.cloneElement(child, props) : child;
            })}
        </Container>
    );
}
