import TargetURL from "../elements/TargetURL";
import { useRef, useState } from "react";
import { Box, Typography, TextField, Button } from "@mui/material";
import LoggerDialog from "../../dialogs/LoggerDialog";


export default function TypeDomain({challenge, handler, ...props}) {
    
    const valueRef = useRef(null);

    const handleChange = (event) => {
        const value = valueRef.current.value;
        // set to lowercase when passing to handler
        handler(value.toLowerCase());
    }

    const preventDefault = (event) => {
        event.preventDefault();
    }

    const [domainExplanationDialogOpen, setdomainExplanationDialogOpen] = useState(false);

    const domainDescription = <>
        <Typography>
            The <emph>domain</emph> of an Internet address is the last part of the address that identifies the website.
        </Typography>
        <Typography>
            For example, in the URL 'https://www.foo.example.com/page', the domain name is 'example.com'.
        </Typography>
    </>
    
    return (
        <Box>
        
            <Typography paddingBottom={2}>You clicked on</Typography>
            <TargetURL url={challenge.url} />

            <Typography paddingTop={2}>Please <b>retype the domain of the website</b> to confirm.</Typography>
            <Typography paddingBottom={2} sx={{fontSize: "0.8rem"}}>(<Button variant='text' onClick={(event) => setdomainExplanationDialogOpen(true)}>What is the domain of a website?</Button>)</Typography>
            
            <TextField 
                className="attention-tutorial-challenge" 
                fullWidth 
                placeholder='Domain' 
                variant='outlined' 
                inputRef={valueRef} 
                onChange={handleChange}
                onCut={preventDefault}
                onCopy={preventDefault}
                onPaste={preventDefault}
                onDrop={preventDefault}
            />

            <LoggerDialog
                open={domainExplanationDialogOpen}
                onClose={() => setdomainExplanationDialogOpen(false)}
                title="What is a domain name?"
                description={domainDescription}
                eventName="domain_description_type"
                cancelText="Ok" />

        </Box>
        
    );
}