import { Box, Typography } from '@mui/material';
import { Title } from '../components/base/typography';

export default function Mobile() {
    return (
        <Box>
            <Title>Unsupported</Title>
            <Typography>
                This application is not supported on mobile devices. Please use a desktop or laptop computer.
            </Typography>
        </Box>
    )
}