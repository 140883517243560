import { Button, Stack } from "@mui/material";
import { Report } from "@mui/icons-material";

export default function AttentionButtons({solved, proceedHandler, goBackHandler, reportHandler, ...props}) {

    return (
        <Stack spacing={2} justifyContent="center" className="attention-tutorial-buttons">
            <Stack spacing={2} direction="row" justifyContent="center">
                <Button variant="contained" disabled={!solved} onClick={proceedHandler} className="attention-tutorial-proceed" >Proceed</Button>
                <Button variant="contained" color='background' onClick={goBackHandler} className="attention-tutorial-back" >Go Back</Button>
            </Stack>
            <Stack spacing={2} direction="row" justifyContent="center">
                <Button variant='contained' startIcon={<Report />} color="error" onClick={reportHandler} className="attention-tutorial-report" >Report Suspicious</Button>
            </Stack>
        </Stack>
    )
}