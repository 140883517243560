import { useState } from 'react';
import { Button, Box } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function Consent({consentHandler, ...props}) {
    const [checked, setChecked] = useState(0);

    return (
        <center>
            <Box>
                <FormControlLabel required control={<Checkbox />} label="Check the box to agree and continue." onChange={(event) => setChecked(1 - checked)} />
                <Box py={1}></Box>
                <Button variant="contained" disabled={checked === 0} className="Button" onClick={consentHandler}>
                    Continue
                </Button>


            </Box>
        </center>
    );
}