import React from 'react';
import {Button} from '@mui/material';
import {useDroppable} from '@dnd-kit/core';
import { UrlFragment } from '../../base/typography';

export function URLDrop({urlState, ...props}) {
  const {isOver, setNodeRef} = useDroppable({
    id: props.id,
  });
  const style = {
    color: (isOver || urlState.completed) ? (props.color ||  'black'): "white",
    fontWeight: (props.isDomain) ? 'bold' : 'normal',
    border: (isOver || urlState.completed) ? (`1px solid ${props.color}` || '1px solid #999') : '1px dashed #333',
    backgroundColor: (isOver || urlState.completed) ? "#eee" : 'white',
  };

  
  return (
    <Button variant="outlined" ref={setNodeRef} style={style}>
      <UrlFragment fontWeight={urlState.highlight ? "bold" : "normal"} >{props.children}</UrlFragment>
    </Button>
  );
}