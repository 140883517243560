import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, } from "@mui/material";
import { logEvent, uploadEvent } from "../../networking";
import { useLocalStorage } from "@uidotdev/usehooks";

export default function LoggerDialog({...props}) {

    const [participant, setParticipant] = useLocalStorage("participant", {});

    const {
        open, 
        onOpen = () => undefined,
        onClose = () => undefined, 
        onConfirm = () => undefined,
        eventName = "",
        title = "",
        description = <></>,
        cancelText = '',
        confirmText = '',
    } = props;

    const event = eventName || title;

    const handleClose = () => {
        logEvent(participant._id, `dialog_closed`, {'dialog': event})
        onClose();
    }

    const handleConfirm = () => {
        logEvent(participant._id, `dialog_confirmed`, {'dialog': event})
        onConfirm();
    }

    const handleEnter = () => {
        logEvent(participant._id, `dialog_opened`, {'dialog': event})
        onOpen();
    }

    return (
        <Dialog
            open={open}
            onTransitionEnter={handleEnter}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >

            <DialogTitle id="alert-dialog-title">
                {title !== '' && title}
            </DialogTitle>

            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {description}
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                { cancelText !== '' && <Button onClick={handleClose}> {cancelText} </Button> }
                { confirmText !== '' && <Button onClick={handleConfirm} autoFocus> {confirmText} </Button> }

            </DialogActions>

        </Dialog>
    )
}