import { Box, Typography } from "@mui/material";
import TargetURL from "../elements/TargetURL";
import PlainURL from "../elements/PlainURL";

export default function ShowFailure({answer, target, ...props}) {
    return (
        <Box>
            <Typography>You answered:</Typography>
            <PlainURL url={answer} />
            <br />

            <Typography>But the link will take you to a different website:</Typography>
            <PlainURL url={target.domainTLD} />
            <br />

            <Typography>Are you sure the link is taking you where you think? If you are sure, you can proceed. Otherwise, you can go back and check the email again, or report it to IT.</Typography>
        </Box>
    )
}