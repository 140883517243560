import { useEffect, useMemo } from "react";
import { Box } from "@mui/material";
import dompurify from "dompurify"
import React from "react";

export default function EmailBody({email, listeners=[], ...props}) {

    const emailBody = useMemo(() => dompurify.sanitize(email.body), [email]);
    const shadowDOMDivRef = React.createRef();

    /* A listener looks like this:
    {
        "type": "click",
        "selector": "a",
        "callback": (event) => { ... }
    }
    */

    useEffect(() => {

        const divNode = shadowDOMDivRef.current;
        let shadowRoot = divNode.shadowRoot;

        // If shadowRoot doesn't exist, create it
        if (!shadowRoot) {
            shadowRoot = divNode.attachShadow({ mode: 'open' });
        } 
        else {
            // Clear existing Shadow DOM content
            while (shadowRoot.firstChild) {
                shadowRoot.removeChild(shadowRoot.firstChild);
            }
        }

        shadowRoot.innerHTML = `${emailBody}`;

        listeners.forEach((listener) => {
            let elements = shadowRoot.querySelectorAll(listener.selector);
            elements.forEach((element) => {
                element.addEventListener(listener.type, listener.callback);
            })
        })
    }, [emailBody, listeners]);


    return (
        <Box ref={shadowDOMDivRef} >
        </Box>
    )
}
