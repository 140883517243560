import { Grid, Typography, Avatar } from "@mui/material"
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import EmailRecipient from "./EmailRecipient";


export default function EmailHeader({email, propic='', ...props}) {

    propic = propic || "/broken.png";

    return (
        <Grid container {...props}>

            <Grid item xs={12} sx={{paddingBottom: 4}}>
                <Typography variant='h5'>
                    {email.subject}
                </Typography>
            </Grid>

            <Grid item xs={1}>
            <Avatar
                sx={{width: 56, height: 56}}
                src={propic}
            />
            </Grid>
            <Grid item container xs={11} sx={{ textAlign: "left"}} justifyContent="center" flexDirection="column">
                <Typography color="primary" marginBottom="0.1rem">
                    {email.sender.display_name} &lt;{email.sender.address}&gt;
                </Typography>
                <Typography sx={{fontSize: "0.8rem"}} marginBottom="0.1rem" color="text.secondary">
                    Today, {email.timestamp}
                </Typography>
                <EmailRecipient email={email} />
            </Grid>

        </Grid>


    )


}