import { Button, Grid, Box, Typography, Container, List, ListItem, ListItemText, Drawer, Paper, IconButton, Collapse, Backdrop, CircularProgress } from '@mui/material';
import EmailViewer from '../components/mailbox/EmailViewer';
import Timer from '../components/framework/Timer';
import { useState, useEffect, useMemo } from 'react';
import { BACKEND_ADDRESS } from '../App';
import { ChallengeTypes, ExperimentProgress, baseExperimentStatus, MechanismStatus, Pages, FailureTypes } from '../Types';
import { logEvent } from '../networking';
import ProfilePicture from '../components/persona/ProfilePicture';
import MailboxButton from '../components/mailbox/MailboxButton';
import {Phishing, MarkEmailUnread, Archive, Help, NoEncryption, Title, ChevronRight, ChevronLeft, CheckBox} from '@mui/icons-material';
import EmailsList from '../components/mailbox/EmailsList';
import MailboxButtons from '../components/mailbox/MailboxButtons'
import SelectEmail from '../components/mailbox/SelectEmail';
import LoggerDialog from '../components/dialogs/LoggerDialog';
import { useLocalStorage } from '@uidotdev/usehooks';
import MailboxHeader from '../components/mailbox/MailboxHeader';
import { defaultTourOptions, nextButton, backButton, doneButton, studyProtocol } from './Tutorials';
import { Preformatted } from '../components/base/typography';
import TaskProgress from '../components/framework/TaskProgress';
import Shepherd from 'shepherd.js';
  

export default function MailClient() {

    const [participant, setParticipant] = useLocalStorage("participant", {});

    const [mailList, setMailList] = useLocalStorage("emails", []);
    const [mailCount, setMailCount] = useLocalStorage("mailCount", 0);
    const [openEmail, setOpenEmail] = useLocalStorage("openEmail", '');

    const [leaveDialogOpen, setleaveDialogOpen] = useState(false);
    const [roleplayDialogOpen, setroleplayDialogOpen] = useState(false);
    const [handledDialogOpen, setHandledDialogOpen] = useState(false);
    const [reportedDialogOpen, setReportedDialogOpen] = useState(false);
    const [tutorialOpen, setTutorialOpen] = useState(false);
    const [protocolOpen, setProtocolOpen] = useState(true);

    const [employee, setEmployee] = useLocalStorage("employee", {});
    const [company, setCompany] = useLocalStorage("company", {});

    const [experiment, setExperiment] = useLocalStorage("experimentStatus", baseExperimentStatus);

    // const tour = useMemo(() => {
    //     let tour = new Shepherd.Tour(defaultTourOptions);
    //     tour.on('complete', () => {
    //         setTutorialOpen(false);
    //     });
    //     tour.on('cancel', () => {
    //         setTutorialOpen(false);
    //     });
    //     tour.on('start', () => {
    //         setTutorialOpen(true);
    //         if (!experiment.mailboxOnboarded)
    //             setExperiment({ ...experiment, mailboxOnboarded: true });
    //     });

    //     const mailclientSteps = [
    //         {
    //           text: `You will now be walked through the process of the study.
    //           <br /><br />
    //           This is your inbox: select a mail from the list to open it in the viewer and display its content`,
    //           attachTo: { element: '.mailbox-tutorial-1', on: 'right' },
    //           buttons: [ nextButton ]
    //         },
    //         {
    //             text: `You have to process all of Jordan's emails. Follow the instructions of the study protocol, which you can open and close at any time with the Help button.`,
    //             attachTo: { element: '.mailbox-protocol-container', on: 'left' },
    //         },
    //         // {
    //         //   text: `You will have to process Jordan's emails: you can perform different actions on them.
    //         //   <br /><br />
    //         //   If the email contains a link, Jordan is asked to visit the website. You can inspect the link and then click on it to perform the requested action.
    //         //   <br /><br />      
    //         //   Note that this is a roleplay and clicking on the link will NOT take you anywhere - your roleplay character will perform their job on that website.`,
    //         // //   attachTo: { element: '.mailbox-tutorial-2', on: 'left' },
    //         //   //when: { show: () => { if (!openEmail) setOpenEmail(mailList[0]) } }
    //         // },
    //         // { 
    //         //   text: "Otherwise, emails without any links can be simply archived after reading them.",
    //         //   attachTo: { element: '.mailbox-tutorial-3', on: 'bottom' },
    //         // },
    //         // {
    //         //   text: `If the email contains a link, it means Jordan Taylor is asked to visit the website. You can inspect the link and then click on it to perform the requested action.
    //         //   <br /><br />      
    //         //   Note that this is a roleplay and clicking on the link will NOT take you anywhere - your roleplay character will perform their job on that website.`,
    //         //   //attachTo: { element: (() => '.mailbox-tutorial-2 a'), on: 'left' },
    //         // },
    //         // {
    //         //   text: "If you suspect an email is suspicious, you can report it to the IT department by clicking on the Report Suspicious button, instead of visiting the link.",
    //         //   attachTo: { element: '.mailbox-tutorial-5', on: 'bottom' },
    //         // },
    //         { 
    //           text: "If you would like to be reminded of your role in the company and its details you can click on your profile picture.",
    //           attachTo: { element: '.mailbox-tutorial-6', on: 'bottom' },
    //           buttons: [ 
    //             backButton, 
    //             doneButton
    //         ],
    //         }
    //         // { 
    //         //   text: "You can display this tutorial again by clicking on the Help button.",
    //         //   attachTo: { element: '.mailbox-tutorial-7', on: 'bottom' },
    //         //   buttons: [ 
    //         //     backButton, 
    //         //     doneButton
    //         // ],
    //         //   //when: { show: () => { setOpenEmail(null) } }
    //         // },
    //       ];

    //     tour.addSteps(mailclientSteps);
    //     return tour;
    // }, []);

    let listeners = [{
        "type": "click",
        "selector": "a",
        "callback": async (event) => {
            event.preventDefault();
            await logEvent(participant._id, "clicked_link", { 'id': openEmail ? openEmail._id : '' });
            if (participant.group === "control") {
                setHandledDialogOpen(true);
            }
            else {
                let challengeId = null;
                let failureId = null;
                if (participant.group === "baseline") {
                    challengeId = ChallengeTypes.PASSIVE_CONFIRM;
                }
                else if (participant.group === "reorder") {
                    challengeId = ChallengeTypes.REALIGN_DOMAIN;
                }
                else {
                    challengeId = event.currentTarget.getAttribute("data-challenge") || ChallengeTypes.AUTO_DOMAIN;
                    failureId = event.currentTarget.getAttribute("data-failure") || FailureTypes.SHOW;
                }
                setExperiment({ ...experiment, clickedUrl: event.currentTarget.href, challengeId: challengeId, failureId: failureId, page: Pages.ATTENTION, sawChallenges: [...experiment.sawChallenges, challengeId] });
                return false;
            }
        }
    }];

    useEffect(() => {
        document.getElementById("mailbox-viewer").scrollTo(0, 0);
      }, [openEmail]);

    const roleplayDescription = useMemo(() => (
        <List>{
        [
        <>• You are {employee.name} {employee.surname}. Your responsibilities are <b>{employee.responsibilities[0]} and {employee.responsibilities[1]}</b>.</>,
        <>• {company.name}'s domains are <Preformatted>{company.domains[0]}</Preformatted> and <Preformatted>{company.domains[1]}</Preformatted></>,
        <>• Personal corporate email addresses: <Preformatted>name.surname@{company.domains[0]}</Preformatted></>,
        <>• {company.name} document and communication tools: Google Drive (<Preformatted>drive.google.com</Preformatted>), Sharepoint (<Preformatted>sharepoint.com</Preformatted>), Microsoft Teams (<Preformatted>teams.microsoft.com</Preformatted>)</>
    ].map((step, index) => (
        <ListItem key={index}>
            <ListItemText>
                {step}
            </ListItemText>
        </ListItem>
    ))
}
    </List>
    ), [company]);

    const handleListClick = (event, email) => {
        logEvent(participant._id, "mail_selected", { 'id': email ? email._id : '' });
        setMailList(mailList.map((mail) => {
            if (mail._id === email._id) {
                mail.read = true;
                mail.selected = true;
            }
            else {
                mail.selected = false;
            }
            return mail;
        }));
        setOpenEmail(email);
    };

    function handleProcessedEmail() {
        logEvent(participant._id, "mail_processed", { 'id': openEmail ? openEmail._id : '' });
        let remainingEmails = mailList.filter(email => email._id !== openEmail._id);
        setMailList(remainingEmails);
        setOpenEmail(null);

        const remainingWithLinks = remainingEmails.filter(email => email.url);

        if (remainingEmails.length === 0 || remainingWithLinks.length === 0) {
            logEvent(participant._id, "leave_finished", {});
            setExperiment({ ...experiment, status: ExperimentProgress.OVER, page: Pages.DEBRIEFING, mechanism: MechanismStatus.NOT_FROM_MECHANISM });
        }
    }

    const handleLeave = () => {
        logEvent(participant._id, "leave_early", {});
        setleaveDialogOpen(false);
        setExperiment({ ...experiment, status: ExperimentProgress.LEFT_EARLY, page: Pages.DEBRIEFING })
    };

    const handleTimeout = () => {
        logEvent(participant._id, "leave_timeout", {});
        setExperiment({ ...experiment, status: ExperimentProgress.TIMEOUT, page: Pages.DEBRIEFING })
    };

    const handleMarkUnread = () => {
        logEvent(participant._id, "mail_unread", { 'id': openEmail ? openEmail._id : '' });
        setMailList(mailList.map((mail) => {
            if (mail._id === openEmail._id) {
                mail.read = false;
                mail.selected = false;
            }
            return mail;
        }));
        setOpenEmail(null);
    };

    const handleArchive = () => {
        logEvent(participant._id, "mail_archived", { 'id': openEmail ? openEmail._id : '' });
        handleProcessedEmail();
    };

    const handleSpamReport = () => {
        logEvent(participant._id, "mail_report", { 'id': openEmail ? openEmail._id : '' });
        setReportedDialogOpen(true);
    };

    const handleHandledEmailConfirm = () => {
        logEvent(participant._id, "mail_process", { 'id': openEmail ? openEmail._id : '' });
        setHandledDialogOpen(false);
        handleProcessedEmail();
    };

    const handleSpamReportConfirm = () => {
        logEvent(participant._id, "mail_reported", { 'id': openEmail ? openEmail._id : '' });
        setReportedDialogOpen(false);
        handleProcessedEmail();
    };

    const handleRoleplayDialog = () => {
        logEvent(participant._id, "mail_roleplay", {});
        setroleplayDialogOpen(true);
    };

    // const displayTutorial = () => {
    //     logEvent(participant._id, "mail_tutorial", { 'id': openEmail ? openEmail._id : '' });
    //     showTour();
    // };

    const displayProtocol = () => {
        logEvent(participant._id, "mail_protocol", { 'id': openEmail ? openEmail._id : '' })
        setProtocolOpen(!protocolOpen);
    };

    // const showTour = () => {
    //     if (!tour.isActive() && !tutorialOpen) {
    //         tour.start();
    //     }
    // }

    // if (!experiment.mailboxOnboarded) {
    //     showTour();
    // }


    useEffect(() => {

        if (experiment.status === ExperimentProgress.NOT_STARTED && mailCount === 0) {
            logEvent(participant._id, "experiment_start", {});
            setExperiment({ ...experiment, status: ExperimentProgress.STARTED });
            console.log("Loading mail list.");
            fetch(`${BACKEND_ADDRESS}/emails/user/${participant._id}`, {
                method: 'GET',
                headers: { 'Content-type': 'application/json' },
            })
                .then(async (res) => {
                    console.log("Mail list loaded.");
                    const res_json = await res.json();
                    // Sort res_json array by timestamp, ascending
                    let emails = res_json.sort((a, b) => (a.timestamp > b.timestamp) ? 1 : -1);
                    const times = ['08:14', '08:22', '08:28', '08:31', '08:37', '08:45', '08:49', '08:57', '09:02', '09:12', '09:18', '09:23', '09:36', '09:44', '09:59', '10:05', '10:25', '10:41', '10:48', '10:53']
                    emails = res_json.map((email, index) => {
                        email.timestamp = times[index];
                        return email;
                    });
                    if (res.status === 200) {
                        setMailList(emails);
                        setMailCount(emails.length);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }

    });

    if (experiment.mechanism !== MechanismStatus.NOT_FROM_MECHANISM) {
        setExperiment({ ...experiment, mechanism: MechanismStatus.NOT_FROM_MECHANISM });
        console.log(`Coming from mechanism. ${experiment.mechanism}`);
        switch (experiment.mechanism) {
            case MechanismStatus.VISITED:
                setHandledDialogOpen(true);
                break;
            case MechanismStatus.ABANDONED:
                // Do nothing. Keeps the email open.
                break;
            case MechanismStatus.REPORTED:
                handleSpamReportConfirm();
                break;
            default:
                console.log(`Unknown mechanism status ${experiment.mechanism}.`)
                break;
        }
    }

    return (
        <Box className="mailbox-container">
            <Box className="mailbox-header">
                <MailboxHeader name={company.name} logo={company.logo}>

                    <Button variant="outline" onClick={(event) => setleaveDialogOpen(true)} sx={{ color: "white", border: "1px white solid" }}>
                        <Typography>Leave the experiment.</Typography>
                    </Button>

                    <TaskProgress
                        progress={mailCount - mailList.length}
                        total={mailCount} />

                    <Timer
                        minutes={parseInt(process.env.REACT_APP_EXPERIMENT_TIMER) || 15}
                        paused={tutorialOpen}
                        onTimeout={handleTimeout} />

                    <Button onClick={() => handleRoleplayDialog()} color='inherit' className={"mailbox-tutorial-6"}>
                        <ProfilePicture employee={employee} company={company} />
                    </Button>

                </MailboxHeader>
            </Box>
            <Box className="mailbox-buttons">
                <MailboxButtons>
                    <MailboxButton image={<MarkEmailUnread fontSize='large' />} callback={handleMarkUnread} disabled={!openEmail}>
                        Mark Unread
                    </MailboxButton>
                    <MailboxButton image={<CheckBox fontSize='large' />} callback={handleArchive} disabled={!openEmail || openEmail.url !== null} className={"mailbox-archive"}>
                        Mark as Completed
                    </MailboxButton>
                    <MailboxButton image={<Phishing fontSize='large' />} callback={handleSpamReport} disabled={!openEmail} className={"mailbox-tutorial-5"}>
                        Report Suspicious
                    </MailboxButton>
                    <MailboxButton image={<Help fontSize='large' />} callback={displayProtocol} className={"mailbox-tutorial-7"}>
                        Help
                    </MailboxButton>
                </MailboxButtons>
            </Box>
            <Box className="mailbox-body">
                <Box className="mailbox-list-container">
                    <Typography variant='h5' sx={{ px: "1rem" }}>
                        Inbox
                    </Typography>
                    <Box className="mailbox-list mailbox-tutorial-1" >
                        <EmailsList emails={mailList} clickHandler={handleListClick} />
                    </Box>
                </Box>
                <Box className="mailbox-viewer-container mailbox-tutorial-2">
                    <Box className="mailbox-viewer" id="mailbox-viewer">
                    {openEmail ?
                        <EmailViewer email={openEmail} listeners={listeners} /> :
                        <SelectEmail />
                    }
                    </Box>
                </Box>
                <Box className="mailbox-protocol-container">
                    <Paper className='mailbox-protocol' elevation={3} sx={{width: "100%", height: "100%"}}>

                        <IconButton onClick={() => setProtocolOpen(!protocolOpen)}>
                        {protocolOpen ? <ChevronRight /> : <ChevronLeft />}
                        </IconButton>
                    <Collapse in={protocolOpen} orientation='horizontal'>
                        {studyProtocol}
                    </Collapse>
                    </Paper>
                </Box>
            </Box>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={!mailCount}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
                   

            <LoggerDialog
                open={roleplayDialogOpen}
                onClose={() => setroleplayDialogOpen(false)}
                title="Roleplay information"
                description={roleplayDescription}
                eventName="roleplay_dialog"
                cancelText="Ok" />

            <LoggerDialog
                open={leaveDialogOpen}
                onClose={() => setleaveDialogOpen(false)}
                title="Leave experiment"
                description="Do you really want to leave? You will not be allowed to come back."
                eventName="leave_experiment"
                confirmText="Leave"
                cancelText="Cancel"
                onConfirm={handleLeave} />

            <LoggerDialog
                open={handledDialogOpen}
                onConfirm={handleHandledEmailConfirm}
                onClose={() => setHandledDialogOpen(false)}
                title="Email handled"
                description="You performed the required action on the linked website and can continue handling your other emails."
                eventName="email_handled"
                confirmText="Ok" />

            <LoggerDialog
                open={reportedDialogOpen}
                onConfirm={handleSpamReportConfirm}
                onClose={() => setReportedDialogOpen(false)}
                title="Report email?"
                description="Do you want to report this email to the IT department?"
                eventName="email_reported"
                confirmText="Report"
                cancelText="Cancel" />

            

        </Box>

    );
}