import { useEffect, useState } from 'react';
import { Button, Box, Container, Typography, List, ListItem, ListItemText, Grid, Skeleton } from '@mui/material'
import { BACKEND_ADDRESS } from '../App';
import { Preformatted } from '../components/base/typography';
import EmployeeBadge from '../components/persona/EmployeeBadge';
import { useLocalStorage } from "@uidotdev/usehooks";
import { Title } from "../components/base/typography"
import { Pages } from '../Types';


export default function Background() {

    const [participant, setParticipant] = useLocalStorage("participant", {});
    const [employee, setEmployee] = useLocalStorage("employee", {});
    const [company, setCompany] = useLocalStorage("company", {});
    const [experiment, setExperiment] = useLocalStorage("experimentStatus", {});

    const [loadingEmployee, setLoadingEmployee] = useState(true);
    const [loadingCompany, setLoadingCompany] = useState(true);

    useEffect(() => {
        if (loadingEmployee) {
            fetch(BACKEND_ADDRESS + "/persona", {
                method: 'POST',
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify({ '_id': participant._id })
            })
                .then(async (res) => {
                    let result = await res.json();
                    setEmployee(result);
                    setLoadingEmployee(false);
                })
                .catch((err) => console.log(err));
        }

        if (loadingCompany) {
            fetch(BACKEND_ADDRESS + "/company", {
                method: 'POST',
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify({ '_id': participant._id })
            })
                .then(async (res) => {
                    let result = await res.json();
                    setCompany(result);
                    setLoadingCompany(false);
                })
                .catch((err) => console.log(err));
        }

    }, []);


    const handleClickConfirm = () => {
        setExperiment({ ...experiment, page: Pages.MAILBOX_DEMO })
    };

    const handleProfilePicture = (picture) => {
        setEmployee({ ...employee, picture: picture });
    }

    return (
        <Container>
            <Grid container spacing={4}>

                <Grid item xs={8}>
                    <Title>
                        Your Background
                    </Title>
                    {loadingEmployee || loadingCompany ? (
                        <>
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                        </>
                    ) : (
                        <>
                            <Typography>
                                In this study you will roleplay as <b>{employee.name} {employee.surname}</b>, working at <b>{company.name}</b>. This is their employee badge: you can change their appearance there.
                            </Typography>
                            <Typography paddingBottom={4}>
                                {employee.name}'s responsibilities are <b>{employee.responsibilities[0]} and {employee.responsibilities[1]}</b>.
                            </Typography>
                            <Typography>
                                In the following the information your character {employee.name} knows about their company:
                            </Typography>
                            <List>
                                <ListItem>
                                    <ListItemText>• {company.name}'s websites are <Preformatted>{company.domains[0]}</Preformatted> and <Preformatted>{company.domains[1]}</Preformatted></ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemText>• Your corporate email address is <Preformatted>{employee.email}</Preformatted></ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemText>• Personal corporate email addresses follow the <Preformatted>name.surname@{company.domains[0]}</Preformatted> structure;
                                        specific units can have dedicated email addresses (e.g., Human Resources writes from <Preformatted>hr@{company.domains[0]}</Preformatted>)</ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemText>• {company.name} uses some external services for documents and communication: Google Drive (<Preformatted>drive.google.com</Preformatted>), Sharepoint (<Preformatted>sharepoint.com</Preformatted>), Microsoft Teams (<Preformatted>teams.microsoft.com</Preformatted>)</ListItemText>
                                </ListItem>
                            </List>

                            <Typography>
                                <b>Your task is to manage {employee.name}'s email inbox</b>: you will have limited time to act on as many emails as possible.
                                You will be now walked through a tutorial that will show you how to perform your tasks.
                            </Typography>

                            <Button variant="contained" className="Button" onClick={handleClickConfirm} sx={{ marginTop: '2rem' }}>
                                Continue
                            </Button>
                        </>
                    )
                    }
                </Grid>

                <Grid item xs={4}>
                    {loadingEmployee || loadingCompany ? (
                        <Skeleton variant="circular" width={200} height={200} />
                    ) : (
                        <EmployeeBadge
                            employee={employee}
                            company={company}
                            pictureSelector={true}
                            pictureSelectorHandler={handleProfilePicture}
                        />
                    )
                    }
                </Grid>

            </Grid>
        </Container>
    );
}