import { List } from '@mui/material'
import EmailsListItem from './EmailsListItem'

export default function EmailsList({emails, clickHandler, ...props}) {

    return ( 
        <List sx={{margin: 0}} {...props}>
            {emails.map((email) => ( <EmailsListItem email={email} clickHandler={clickHandler} key={`list_email_${email._id}`}/> ))}
        </List>
    )

}