import { Box, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import TargetURL from '../elements/TargetURL';
import { useState } from 'react';

export default function ClickDomainFromList({ challenge, handler, ...props }) {

    const [selectedValue, setSelectedValue] = useState('');

    const handleChange = (event, newValue) => {
        setSelectedValue(newValue);
        handler(newValue);
    }


    return (
        <Box textAlign={"center"} >
            <Typography variant='body1' paddingBottom={2}>You clicked on</Typography>

                <TargetURL url={challenge.url} />


            <Typography variant='body1' py={2}>
                Which website do you <b>intend to access?</b> Pick from the ones below.
            </Typography>

            <Box className="attention-tutorial-challenge">
                <ToggleButtonGroup
                    color="primary"
                    value={selectedValue}
                    exclusive
                    orientation="vertical"
                    onChange={handleChange}
                    aria-label="Answer options"
                >
                    {challenge.propositions.map((label, index) => (
                        <ToggleButton key={index} value={label} aria-label={label}>
                            <Typography variant='body2' fontSize="1rem">{label}</Typography>
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            </Box>
        </Box>
    );
}