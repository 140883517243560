import { FormLabel, RadioGroup, FormControlLabel, Radio } from "@mui/material"

export default function QuestionnaireRadio({label, values, noAnswer=true, ...props}) {

    let handleChange = props.onChange ? props.onChange : () => undefined;
    const labelPlacement = props.labelPlacement ? props.labelPlacement : 'bottom';

    return (
        <div className='questionnaire-row'>
            <FormLabel id={`${label}-radio-button`}  className="questionnaire-label">
                {label}
            </FormLabel>
            <RadioGroup onChange={handleChange} row aria-labelledby={`${label}-radio-button`} name={label}>

                {
                    values.map(({value, label}, i) => (
                        <FormControlLabel labelPlacement={labelPlacement} value={value} control={<Radio />} key={`${label}-${value}`} label={label} />
                    ))
                }
                
                {noAnswer ? <FormControlLabel labelPlacement={labelPlacement} value="no answer" control={<Radio />} label="Prefer not to answer" /> : ''}
                
            </RadioGroup>
        </div>
    )
}