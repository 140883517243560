import React, { useState } from 'react';
import { Box, Stack } from '@mui/material';
import { KeyboardDoubleArrowDown, KeyboardDoubleArrowUp } from '@mui/icons-material';
import RecipientShort from './RecipientShort'; 
import RecipientLong from './RecipientLong';
import { EmailHeader } from '../base/typography';

export default function EmailRecipient({ email }) {
    const [showLongRecipient, setShowLongRecipient] = useState(false);

    const toggleRecipient = () => {
        setShowLongRecipient(!showLongRecipient);
    };

    return (
        <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
        >
            <Box>
                {showLongRecipient ? <RecipientLong email={email} /> : <RecipientShort email={email} />}
            </Box>
            <Box>
                <EmailHeader>
                {showLongRecipient ? (
                    <KeyboardDoubleArrowUp onClick={(toggleRecipient)} sx={{fontSize: "1rem"}} />
                ) : (
                    <KeyboardDoubleArrowDown onClick={toggleRecipient} sx={{fontSize: "1rem"}} />
                )}
                </EmailHeader>
            </Box>
        </Stack>
    );
}

