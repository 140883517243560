import React from 'react';
import {Button} from '@mui/material';
import {useDraggable} from '@dnd-kit/core';
import { UrlFragment } from '../../base/typography';

export function URLBlock({urlState, placement, ...props}) {

  const {attributes, listeners, setNodeRef, transform, isDragging, over} = useDraggable({
    id: props.id,
  });

  const style = {
    transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
    zIndex: 100,
    visibility: (!urlState.completed && urlState.placement == placement) ? 'visible' : 'hidden',
    fontWeight: urlState.highlight ? 'bold' : 'normal',
    color: (props.color || 'black'),
    borderColor: (props.color || 'black'),
  };

  
  return (
    <Button variant="outlined" ref={setNodeRef} style={style} {...listeners} {...attributes}>
      <UrlFragment fontWeight={urlState.highlight ? "bold" : "normal"} >{props.children}</UrlFragment>
    </Button>
  );
}