import { EmailHeader } from '../base/typography'

export default function RecipientShort({email, ...props}) {

    let recipients = email.recipients.map((recipient) => recipient.display_name || recipient.address);

    return (
        <EmailHeader>
            To: {recipients.join(", ")}
        </EmailHeader>
    )
}