import { AppBar, Avatar, Container, Divider, Grid, Stack, Toolbar, Typography } from '@mui/material';
import { Title } from '../base/typography';


export default function MailboxHeader({ name = null, logo = null, ...props }) {
    return (
        <Grid container>
            <AppBar position="sticky">
                <Container maxWidth="xl">
                    <Toolbar disableGutters>

                        {logo && <Avatar src={logo} sx={{ height: "50px", width: "50px", mr: "16px" }} />}
                        <Title gutterBottom>{name ? name : 'Mailbox'}</Title>

                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            divider={<Divider orientation="vertical" variant="middle" flexItem sx={{ borderColor: "#EEE", my: "8px !important" }} />}
                            spacing={2}
                            sx={{ width: 1 }}
                        >
                            {props.children}
                        </Stack>
                        
                    </Toolbar>
                </Container>
            </AppBar>
        </Grid>
    );
};
