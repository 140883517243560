import { useMemo } from "react";
import TargetURL from "../elements/TargetURL";
import { Box, Typography } from "@mui/material";

export default function DisplayDomain({ challenge, handler, ...props }) {

    useMemo(() => {
        handler(challenge.solution);
    }, []);

    return (
        <Box>
            <Typography paddingBottom={2}>Do you want to proceed to</Typography>

            <TargetURL url={challenge.url} />

        </Box>

    );

}