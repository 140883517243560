import { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import TargetURL from '../elements/TargetURL';
import LoggerDialog from '../../dialogs/LoggerDialog';
import { Preformatted } from '../../base/typography';



export default function SelectDomain({ challenge, handler, ...props }) {

    const handleChange = (event) => {
        let selectedText = window.getSelection().toString().replaceAll(/\n|\r/gi, '');
        handler(selectedText);
    }

    const [domainExplanationDialogOpen, setdomainExplanationDialogOpen] = useState(false);

    const domainDescription = <>
        <Typography>
            The <emph>domain</emph> of an Internet address is the last part of the address that identifies the website.
        </Typography>
        <Typography>
            For example, in the URL <Preformatted>https://www.foo.example.com/page</Preformatted>, the domain name is <Preformatted>example.com</Preformatted>.
        </Typography>
    </>

    return (
        <Box>
            <Typography variant='body1' paddingBottom={2}>You clicked on</Typography>

            <Box paddingBottom={2} onMouseUp={handleChange} >
                <TargetURL url={challenge.url} />
            </Box>

            <Typography variant='body1'> What is the website that you <b>intend to access?</b><br /> Click and <b>drag to select and highlight its domain</b> with your mouse.</Typography>
            <Typography paddingBottom={2} sx={{fontSize: "0.8rem"}}>(<Button variant='text' onClick={(event) => setdomainExplanationDialogOpen(true)}>What is the domain of a website?</Button>)</Typography>

            <LoggerDialog
                open={domainExplanationDialogOpen}
                onClose={() => setdomainExplanationDialogOpen(false)}
                title="What is a domain name?"
                description={domainDescription}
                eventName="domain_description_select"
                cancelText="Ok" />

        </Box>
    );

}