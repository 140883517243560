import { Avatar, CardContent, Typography, Stack } from "@mui/material";


export default function ProfilePicture({employee, company, ...props}) {

    return (
        <Stack 
            direction="row" 
            justifyContent="flex-end"
            alignItems="center"
        >

            <CardContent align="right">

                <Typography variant="h6" component="p" justifyContent="end" alignContent="flex-end">
                    {employee.name} {employee.surname}
                </Typography>

                <Typography fontSize="small" color="grey.100" component="p" justifyContent="end">
                    {employee.email}
                </Typography>
                
            </CardContent>

            <Avatar
                sx={{width: 64, height: 64}}
                src={`images/avatars/${employee.picture}`}
                title={employee.name}
                className='pfp'
            />

        </Stack>
    )
}