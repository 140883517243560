import { ChallengeTypes, FailureTypes } from "../../Types.js";
import ClickDomainFromList from "./challenges/ClickDomainFromList";
import SelectDomain from "./challenges/SelectDomain";
import TypeDomain from "./challenges/TypeDomain";
import DisplayDomain from "./challenges/DisplayDomain";
import RealignDomain from "./challenges/RealignDomain";
import { formatURL } from "../../utils.js";
import { BACKEND_ADDRESS } from "../../App.js";
import ShowFailure from "./failures/ShowFailure.js";
import ImpersonationFailure from "./failures/ImpersonationFailure.js";
import { Difference } from "@mui/icons-material";
import DifferenceFailure from "./failures/DifferenceFailure.js";


// Challenge: Find the domain among subdomains
export async function CreateClickDomainChallenge(stringUrl) {
    const url = formatURL(stringUrl);

    let propositions = [];
    const solution = url.domainTLD;

    await fetch(`${BACKEND_ADDRESS}/fragments/${encodeURIComponent(stringUrl)}`, {
        method: 'GET',
        headers: { 'Content-type': 'application/json' },
    })
    .then(async (res) => {
        const res_json = await res.json();
        if (res.status === 200) {
            console.log(res_json);
            propositions = res_json;
        }
    })
    .catch((err) => {
        console.log(err);
    });

    propositions.sort(function (a, b) {return Math.random() - 0.5;});
    return {
        url: url, 
        propositions: propositions, 
        solution: solution,
        checkSolution: ((answer) => answer === solution )
    }
};

export function CreateSelectDomainChallenge(stringUrl){
    const url = formatURL(stringUrl);
    const solution = [url.domainTLD, url.domain, url.host];

    return {
        url: url,
        propositions: [], 
        solution: solution,
        checkSolution: ((answer) => solution.includes(answer.replace(/^\.|\/$/g, '')))
    }
};

export function CreateTypeDomainChallenge(stringUrl){
    const url = formatURL(stringUrl);
    const solution = [
        url.domainTLD,
        url.domain,
        url.host
    ];

    return {
        url: url,
        propositions: [], 
        solution: solution,
        checkSolution: ((answer) => solution.includes(answer))
    };
}

export function CreateDisplayDomainChallenge(stringUrl){
    const url = formatURL(stringUrl);

    return {
        url: url,
        propositions: [],
        solution: url.host,
        checkSolution: ((answer) => true)};
}

export function CreateRealignDomainChallenge(stringUrl){

    const url = formatURL(stringUrl);

    return {
        url: url, 
        propositions: [...url.subdomains, url.domain, url.tld],
        solution: url.host,
        checkSolution: ((answer) => true)};
}

export async function CreateChallenge(url, challengeId){
    switch (challengeId) {
        case ChallengeTypes.CLICK_DOMAIN:
            return CreateClickDomainChallenge(url);
        case ChallengeTypes.SELECT_DOMAIN:
            return CreateSelectDomainChallenge(url);
        case ChallengeTypes.TYPE_DOMAIN:
            return CreateTypeDomainChallenge(url);
        case ChallengeTypes.PASSIVE_CONFIRM:
            return CreateDisplayDomainChallenge(url);
        case ChallengeTypes.REALIGN_DOMAIN:
            return CreateRealignDomainChallenge(url);
        default:
            return CreateClickDomainChallenge(url);
    }
}

export function CreateChallengeElement(challengeId, challenge, inputHandler){
    switch (challengeId) {
        case ChallengeTypes.CLICK_DOMAIN:
            return <ClickDomainFromList challenge={challenge} handler={inputHandler}/>;
        case ChallengeTypes.SELECT_DOMAIN:
            return <SelectDomain challenge={challenge} handler={inputHandler}/>;
        case ChallengeTypes.TYPE_DOMAIN:
            return <TypeDomain challenge={challenge} handler={inputHandler}/>;
        case ChallengeTypes.PASSIVE_CONFIRM:
            return <DisplayDomain challenge={challenge} handler={inputHandler}/>;
        case ChallengeTypes.REALIGN_DOMAIN:
            return <RealignDomain challenge={challenge} handler={inputHandler}/>;
        default:
            return <ClickDomainFromList challenge={challenge} handler={inputHandler}/>;
    }
}

export function CreateFailureElement(failureId, answer, target){
    switch (failureId) {
        case FailureTypes.SHOW:
            return <ShowFailure answer={answer} target={target} />;
        case FailureTypes.IMPERSONATION:
            return <ImpersonationFailure answer={answer} target={target} />;
        case FailureTypes.DIFFERENCE:
            return <DifferenceFailure answer={answer} target={target} />;
        default:
            return <ShowFailure answer={answer} target={target} />;
    }
}