import React from 'react';
import { Box } from '@mui/material';
import Dot from './Dot';
import { formatURL } from "../../../utils";
import { UrlFragment } from '../../base/typography';


export default function TargetURL({ url, color=true, ...props }) {

    const colors = ["#BD376A", "#B84600", "#8E6200", "#006FB3", "#4867B8", "#6C55D8"];
    colors.sort(() => (Math.random() - 0.5));


    return (
        <Box className="attention-tutorial-address challenge-url" sx={{ wordBreak: 'break-all' }}>
            {url.subdomains.map((value, index) => (
                <>
                    <UrlFragment color={color ? colors[index % colors.length] : ''} key={value} >
                        {value}
                    </UrlFragment>
                    {url.subdomains.length > 0 && <Dot />}
                </>
            ))}
            <UrlFragment color={color ? colors[url.subdomains.length] : ''} fontWeight={"bold"} key={url.domain} >{url.domain}</UrlFragment>
            <Dot />
            <UrlFragment color={color ? colors[url.subdomains.length + 1] : ''} fontWeight={"bold"} key={url.tld} >{url.tld}</UrlFragment>
            <UrlFragment color="#999" key={url.path} >{url.path}</UrlFragment>
        </Box>
    );
}
