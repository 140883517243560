import { CLICK_DOMAIN, SELECT_DOMAIN, TYPE_DOMAIN } from './Types';


export function htmlDecode(input){
    var e = document.createElement('div');
    e.innerHTML = input;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
}

export function colorDifference(reference, submission){
    let resultRef = "";
    let resultSub = "";
    let workingRef = reference;
    let workingSub = submission;
    while (workingRef.length > 0 && workingSub.length > 0) {
        if(workingRef[0] === workingSub[0]){
            resultSub += workingSub[0];
            resultRef += workingRef[0];
            workingRef = workingRef.substring(1);
            workingSub = workingSub.substring(1);
        }
        else if (workingRef.length > 1 && levenshteinDistance(workingRef.substring(1), workingSub) < levenshteinDistance(workingRef, workingSub)){
            resultRef += '&lt;span style="color: red;"&gt;' + workingRef[0] + '&lt;/span&gt;';
            workingRef = workingRef.substring(1);
        }
        else if (workingSub.length > 1 && levenshteinDistance(workingRef, workingSub.substring(1)) < levenshteinDistance(workingRef, workingSub)){
            resultSub += '&lt;span style="color: red;"&gt;' + workingSub[0] + '&lt;/span&gt;';
            workingSub = workingSub.substring(1);
        }
        else{
            resultRef += workingRef[0];
            resultSub += '&lt;span style="color: red;"&gt;' + workingSub[0] + '&lt;/span&gt;';
            workingRef = workingRef.substring(1);
            workingSub = workingSub.substring(1);
        }
    }

    for(var i = 0 ; i < workingRef.length ; i += 1){
        resultRef += '&lt;span style="color: red;"&gt;' + workingRef[i] + '&lt;/span&gt;';
    }
    for(var i = 0 ; i < workingSub.length ; i += 1){
        resultSub += '&lt;span style="color: red;"&gt;' + workingSub[i] + '&lt;/span&gt;';
    }
    
    return {coloredRef: resultRef, coloredSub: resultSub};
}

export function levenshteinDistance(str1, str2) {
    const track = Array(str2.length + 1).fill(null).map(() => Array(str1.length + 1).fill(null));
    for (let i = 0; i <= str1.length; i += 1) {
       track[0][i] = i;
    }
    for (let j = 0; j <= str2.length; j += 1) {
       track[j][0] = j;
    }
    for (let j = 1; j <= str2.length; j += 1) {
       for (let i = 1; i <= str1.length; i += 1) {
          const indicator = str1[i - 1] === str2[j - 1] ? 0 : 1;
          track[j][i] = Math.min(
             track[j][i - 1] + 1, // deletion
             track[j - 1][i] + 1, // insertion
             track[j - 1][i - 1] + indicator, // substitution
          );
       }
    }
    return track[str2.length][str1.length];
 }

 const popular_TLD = ["com", "ch", "org", "fr", "de"];
 export function pickChallenge(url){

    return Math.floor(Math.random() * 3) + 1;

    // let parsedUrl = SplitDomain(url);
    // let subdomains = parsedUrl.subdomains.split('.');
    // let subdomainWords = parsedUrl.subdomains.split(/[.-]+/);
    // let domainTLD = parsedUrl.domainTLD.split('.');
    // let domain = domainTLD[0];
    // let domainWords = domain.split(/[-]/);
    // let path = parsedUrl.path;
    // let pathWords = path.split(/[-._@/]/);
    // let pathDepth = path.split('/').length;

    // let click_weight = 0;
    // let select_weight = 0;
    // let type_weight = 0;

    // click_weight += subdomainWords.length - subdomains.length;
    // click_weight += domainWords.length - 1;
    
    // for(let i = 0 ; i < subdomainWords.length ; i += 1){
    //     if(popular_TLD.includes(subdomainWords[i])){
    //         click_weight += 1;
    //         break;
    //     }
    //     if(subdomainWords[i].length > 15){
    //         click_weight += 1;
    //     }
    // }

    // if(domain.length > 15){
    //     type_weight += 2;
    // }
 
    // select_weight += pathWords.length - pathDepth;
    // for(let i = 0 ; i < pathWords.length ; i += 1){
    //     if(popular_TLD.includes(pathWords[i])){
    //         select_weight += 1;
    //         break;
    //     }
    // }

    // if(click_weight >= type_weight){
    //     if(select_weight > click_weight){
    //         return SELECT_DOMAIN;
    //     }
    //     else{
    //         return CLICK_DOMAIN;
    //     }
    // }
    // else{
    //     if(select_weight > type_weight){
    //         return SELECT_DOMAIN;
    //     }
    //     else{
    //         return TYPE_DOMAIN;
    //     }
    // }
 }
export function formatURL(url) {
    let parsedUrl = '';
    try {
        parsedUrl = new URL(url);
    } catch (error) {
        console.log(error);
        console.log(`bad URL ${url}`);
        return null;
    }
    let host = parsedUrl.hostname;
    const hostSplit = host.split(".");
    let TLD = hostSplit.pop();
    let domain = hostSplit.pop();
    return {
        "protocol": parsedUrl.protocol,
        "host": parsedUrl.host,
        "path": parsedUrl.pathname,
        "domain": domain,
        "tld": TLD,
        "domainTLD": `${domain}.${TLD}`,
        "subdomains": hostSplit,
    };
}
