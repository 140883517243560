import { React } from "react";
import { styled } from '@mui/material/styles';
import { Typography, Stack, ButtonBase } from "@mui/material";
import { ErrorOutline } from '@mui/icons-material';

const IconButton = styled(ButtonBase)(({ theme }) => ({
    position: 'relative',
    height: 200,
    [theme.breakpoints.down('sm')]: {
      width: '100% !important', // Overrides inline-style
      height: 100,
    },
    '&:hover, &.Mui-focusVisible': {
      zIndex: 1,
      backgroundColor: "#EEE"
    },
    '&:hover, &.Mui-disabled': {
    },
    '&.Mui-disabled': {
        opacity: 0.5
    }
  }));

export default function MailboxButton({image=<ErrorOutline />, callback=() => '', ...props}) {

    const buttonImage = image || <ErrorOutline />

    return (
        <IconButton 
            style={{
                width: "128px",
                height: "128px",
                padding: "16px"
            }} 
            onClick={callback}
            disabled={props.disabled}
            {...props}
        >
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
            >
                {buttonImage}
                <Typography fontSize='small'>{props.children}</Typography>

            </Stack>
        </IconButton>
    )
}