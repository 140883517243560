import {useState} from 'react';
import {Button, Box, Typography, Container, Paper} from '@mui/material'
import FormControl from '@mui/material/FormControl';
import { BACKEND_ADDRESS } from '../App';
import { Subtitle, Title } from '../components/base/typography';
import QuestionnaireGroup from '../components/framework/Questionnaire/QuestionnaireGroup';
import QuestionnaireLikert from '../components/framework/Questionnaire/QuestionnaireLikert';
import { useLocalStorage } from '@uidotdev/usehooks';
import { Pages } from '../Types';


let commonQs = [
    ["detecting_spam_by_reading", "I felt conﬁdent in detecting the scam emails by reading them."],
    ["scam_difficult_to_detect", "I felt the scam emails were difficult to detect."],
    ["recognize_legitimate_urls", "I felt the legitimate URLs were easy to recognize as legitimate."],
    ["scam_spotting_difficulty", "I felt the scam URLs were difficult to spot from the email."]
];

let passiveQs = [
    ["re-reading_useful", "Re-reading the clicked URLs on the confirmation page was useful."],
    ["ignored_urls", "I ignored the URLs on the confirmation page."],
    ["changed_mind_at_confirmation", "Seeing the URLs on the confirmation page helped me decide."]
];

let reorderQs = [
    ["re-reading_useful", "Having to reorder the clicked URLs on the confirmation page was useful."],
    ["ignored_urls", "I did not read the URLs while reordering them"],
    ["changed_mind_at_confirmation", "Reordering the URLs on the challenge page helped me decide."]
];

let mechanismQs = [
    ["help_spot_phishing", "The link challenges helped me spot phishing URLs."],
    ["spot_phishing_without_tool", "I did not need the challenges to understand which URLs were phishing."],
    ["clearly_highlighted_mistakes", "The tool clearly highlighted mistakes I made in reading the URLs."],
    ["tool_in_the_way", "The challenges were in the way of doing my job."],
    ["tutorial_clear", "The challenge tutorial was clear."],
    ["tutorial_sufficient_information", "The challenge tutorial presented all the information I needed."],
    ["coloring_url_useful", "Coloring the different URL components was useful."],
    ["wish_reading_url_simpler", "I wish the URL was made simpler to read."],
    ["tool_presentation_confusing", "The challenge presentation was confusing."],
]

let specificQs_list = [
    ["click_domain_challenge_useful", "The challenge to click the domain from a list was useful."],
    ["click_domain_challenge_frustrating", "The challenge to click the domain from a list was annoying."],
    ["click_domain_challenge_difficult", "The challenge to click the domain from a list was difficult."]
]
let specificQs_highlight = [
    ["highlight_domain_challenge_useful", "The challenge to highlight the domain with the mouse was useful."],
    ["highlight_domain_challenge_frustrating", "The challenge to highlight the domain with the mouse was annoying."],
    ["highlight_domain_challenge_difficult", "The challenge to highlight the domain with the mouse was difficult."],
]
let specificQs_type = [
    ["type_domain_challenge_useful", "The challenge to type the domain was useful."],
    ["type_domain_challenge_frustrating", "The challenge to type the domain was annoying."],
    ["type_domain_challenge_difficult", "The challenge to type the domain was difficult."],
]


export default function PostQ() {

    const [feedbackFormValues, setFeedbackFormValues] = useState({});
    const [participant, setParticipant] = useLocalStorage("participant", {});
    const [experiment, setExperiment] = useLocalStorage("experimentStatus", {});

    const handleChange = (event) => {
        
        setFeedbackFormValues({
            ...feedbackFormValues,
            [event.target.name]: event.target.value,
          });
          
    };

    const handleClickConfirm = () => {
        
        fetch(BACKEND_ADDRESS + "/answers",
            {
                method: 'POST',
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify({
                    'participant_id': participant._id,
                    'form_name': 'feedback',
                    'form_data': feedbackFormValues
                })
            }
        )
            .then(async (res) => {
                let result = await res.json();
                setExperiment({ ...experiment, page: Pages.REWARD })
            })
            .catch((err) => console.log(err));
  
        
    };

    function renderQs(questionsList) {

        return (
            <QuestionnaireGroup least="Strongly disagree" most="Strongly agree">
                {
                    questionsList.map((items, idx) => (
                        <QuestionnaireLikert key={idx} label={items[1]} name={items[0]} onChange={handleChange} />
                    ))
                }
            </QuestionnaireGroup>
        )
    }

    return (
        <Container>
            <Title>
                Post-Experiment Questionnaire
            </Title>

        <Typography>
            We'll ask you some final questions about the study. Please fill the following questionnaire to complete your task.
        </Typography>
        <Typography pb={4} pt={2}>
            In the following, reply by selecting a value from 1 ("Strongly disagree") to 5 ("Strongly agree").
        </Typography>

        <FormControl>
            {renderQs(commonQs)}
        </FormControl>

        {participant.group === "baseline" &&
            <>
                <Subtitle>URL Confirmation Page</Subtitle>
                <Typography>
                    The following questions are specific to the page where you had to <b>re-read and confirm the URL</b> of the website you clicked: you can see it again below for a reminder.
                </Typography>
                <FormControl>
                    <Paper elevation={6}>
                        <img src='images/baseline.gif' width="75%" style={{ margin: "1rem auto" }} />
                    </Paper>
                    {renderQs(passiveQs)}
                </FormControl>
            </>
        }

        {participant.group === "reorder" &&
            <>
                <Subtitle>URL Reorder Challenge</Subtitle>
                <Typography>
                    The following questions are specific to the page where you had to <b>reorder the parts of the domain name</b> of the website: you can see it again below for a reminder.
                </Typography>
                <FormControl>
                    <Paper elevation={6}>
                        <img src='images/reorder.gif' width="75%" style={{ margin: "1rem auto" }} />
                    </Paper>
                    {renderQs(reorderQs)}
                </FormControl>
            </>
        }

        {participant.group === "treatment" && experiment.sawChallenges.length &&
            <>
                <Subtitle>Link Challenges</Subtitle>
                <Typography>
                    The following questions are general questions about the security mechanism that asked you to perform some tasks on the URLs.
                </Typography>
                <FormControl>
                    {renderQs(mechanismQs)}
                </FormControl>
            </>
        }

        {participant.group === "treatment" && experiment.sawChallenges.includes("1") &&
            <>
                <Subtitle>Select from List Challenge</Subtitle>
                <Typography mb={4}>
                    The following questions are specific to the challenge that asked you to <b>select from a list the domain name</b> of the website: you can see it again below for a reminder.
                </Typography>
                <FormControl>
                    <Paper elevation={6}>
                        <img src='images/list.gif' width="75%" style={{ margin: "1rem auto" }} />
                    </Paper>
                    {renderQs(specificQs_list)}
                </FormControl></>
        }

        {participant.group === "treatment" && experiment.sawChallenges.includes("2") &&
            <>
                <Subtitle>Highlighting Challenge</Subtitle>
                <Typography mb={4}>
                    The following questions are about the challenge that asked you to <b>highlight the domain name</b> of the website with your mouse: you can see it again below for a reminder.
                </Typography>
                <FormControl>
                    <Paper elevation={6}>
                        <img src='images/highlight.gif' width="75%" style={{margin: "1rem auto"}}/>
                    </Paper>
                    {renderQs(specificQs_highlight)}
                </FormControl>
            </>
        }

        {participant.group === "treatment" && experiment.sawChallenges.includes("3") &&
            <>
                <Subtitle>Retyping Challenge</Subtitle>
                <Typography mb={4}>
                    The following questions are about the challenge that asked you to <b>retype the domain name</b> of the website: you can see it again below for a reminder.
                </Typography>
                <FormControl>
                    <Paper elevation={6}>
                        <img src='images/type.gif' width="75%" style={{margin: "1rem auto"}}/>
                    </Paper>
                    {renderQs(specificQs_type)}
                </FormControl>
            </>
        }

        <Box py={2}></Box> 
        <Button variant="contained" className="Button" onClick={(event) => handleClickConfirm()}>
            Continue
        </Button>
        <p>&nbsp;</p>
        
        </Container>
    );
}
