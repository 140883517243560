import { Box, Typography } from '@mui/material'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

export default function ExternalWarning({text=null, icon=null, ...props}) {
    
    let renderIcon = icon || <WarningRoundedIcon color="warning" sx={{float: "left", marginRight: "10px"}}/>;
    let renderText = text || <span><b>Caution</b>: This email originates from outside your organization.</span>;

    return (
        <Box padding={1} my={2} sx={{borderTop: 1, borderBottom:1, backgroundColor: "#fef8e6"}}>
            {renderIcon}
            <Typography display="inline" sx={{fontSize: "0.8rem"}}>
                {renderText}
            </Typography>
        </Box>
    )

}