import { Button, Grid, Box, Typography, Container, List, ListItem, ListItemText, Drawer, Paper, IconButton, Collapse } from '@mui/material';
import EmailViewer from '../components/mailbox/EmailViewer';
import Timer from '../components/framework/Timer';
import { useState, useEffect, useMemo } from 'react';
import { BACKEND_ADDRESS } from '../App';
import { ChallengeTypes, ExperimentProgress, baseExperimentStatus, MechanismStatus, Pages, FailureTypes, TutorialStatus } from '../Types';
import { logEvent } from '../networking';
import ProfilePicture from '../components/persona/ProfilePicture';
import MailboxButton from '../components/mailbox/MailboxButton';
import {Phishing, MarkEmailUnread, Archive, Help, NoEncryption, Title, ChevronRight, ChevronLeft, CheckBox, Work} from '@mui/icons-material';
import EmailsList from '../components/mailbox/EmailsList';
import MailboxButtons from '../components/mailbox/MailboxButtons'
import SelectEmail from '../components/mailbox/SelectEmail';
import LoggerDialog from '../components/dialogs/LoggerDialog';
import { useLocalStorage } from '@uidotdev/usehooks';
import MailboxHeader from '../components/mailbox/MailboxHeader';
import { defaultTourOptions, nextButton, backButton, doneButton, studyProtocol, tutorialMailList } from './Tutorials';
import { Preformatted } from '../components/base/typography';
import TaskProgress from '../components/framework/TaskProgress';
import Shepherd from 'shepherd.js';
  



export default function MailClientTutorial() {

    const [participant, setParticipant] = useLocalStorage("participant", {});

    // const [mailList, setMailList] = useLocalStorage("emails", []);
    // const [mailCount, setMailCount] = useLocalStorage("mailCount", 0);
    // const [openEmail, setOpenEmail] = useLocalStorage("openEmail", '');

    const [mailList, setMailList] = useState(tutorialMailList);
    const [mailCount, setMailCount] = useState(tutorialMailList.length);
    const [openEmail, setOpenEmail] = useState(tutorialMailList[0]);

    const [leaveDialogOpen, setleaveDialogOpen] = useState(false);
    const [roleplayDialogOpen, setroleplayDialogOpen] = useState(false);
    const [handledDialogOpen, setHandledDialogOpen] = useState(false);
    const [reportedDialogOpen, setReportedDialogOpen] = useState(false);
    const [tutorialOpen, setTutorialOpen] = useState(false);
    const [protocolOpen, setProtocolOpen] = useState(true);
    const [showProtocol, setShowProtocol] = useState(false);
    const [postMechanism, setPostMechanism] = useState(false);

    const [employee, setEmployee] = useLocalStorage("employee", {});
    const [company, setCompany] = useLocalStorage("company", {});

    const [experiment, setExperiment] = useLocalStorage("experimentStatus", baseExperimentStatus);


    const tour = useMemo(() => {
        let tour = new Shepherd.Tour(defaultTourOptions);
        tour.on('complete', () => {
            setTutorialOpen(false);
            setExperiment({ ...experiment, tutorialStatus: TutorialStatus.COMPLETED_FIRST });
            console.log("Tutorial 1 completed");
        });
        tour.on('start', () => {
            setTutorialOpen(true);
        });

        const mailclientSteps = [
            {
                text: `You will now be walked through the process of the study and learn how to manage Jordan's inbox.`,
                buttons: [ nextButton ],
            },
            {
              text: `This is the first email Jordan received today.
              It <b>doesn't contain a link</b>. For these types of emails, you need to briefly read the info provided and then click on the "Mark As Completed" button: Jordan will automatically handle the email.`,
              attachTo: { element: '.mailbox-viewer-container', on: 'left' },
              buttons: [ nextButton ],
            },
            {
                text: `This is Jordan's second email. <b>It contains a link</b>: to manage this type of emails, you need to click on the link, because Jordan is asked to visit the website.
                For example, here Jordan has to check FuturaCom's Facebook page performance.
                <br /><br />
                Note that this is a roleplay and clicking on the link <b>will NOT take you anywhere</b> - your roleplay character will perform their job on that website.
                `,
                attachTo: { element: '.mailbox-viewer-container', on: 'left' },
                buttons: [ {
                    text: "Next",
                    action() {
                      return this.complete();
                    },
                  } ],
                when: { 
                    "before-show": () => { 
                        handleListClick(null, mailList[1]);
                    }
                },
            },
        ];
        tour.addSteps(mailclientSteps);
        return tour;
    }, [experiment.page]);

    const tour2 = useMemo(() => {
        let tour = new Shepherd.Tour(defaultTourOptions);
        tour.on('complete', () => {
            setTutorialOpen(false);
            setExperiment({ ...experiment, tutorialStatus: TutorialStatus.COMPLETED, page: Pages.MAILBOX });
        });
        tour.on('start', () => {
            setTutorialOpen(true);
        });

        const mailclientSteps = [
            { 
                text: `Sometimes, we receive <b>undesired email</b>, or even malicious ones that try to deceive and get advantage of us. You might have received similar ones yourself, and it also happens to employees in organizations like Jordan.`,
                buttons: [ nextButton ],
                when : { 
                    "before-show": () => {
                        handleListClick(null, mailList[2]);
                    }
                }
            },
            {
                text: `This is Jordan's third email, which is a <b>suspicious email</b>: it is a phishing attempt where someone posing as tech support is asking for their password. 
                <br /> 
                In case you see any suspicious emails, report it to IT with the "Report Suspicious" button to manage it, instead of clicking on the link.`,
                attachTo: { element: (() => '.mailbox-viewer-container'), on: 'left' },
                buttons: [ nextButton ],
            },
            {
                text: `This is the end of the tutorial: you will now manage Jordan's emails under time pressure. You will have a maximum of 15 minutes to process as many emails as possible.
                <br /><br />
                You will be reminded of the study instructions in this tab on the right, and you can click on Jordan's profile picture to get reminded of their roleplay information.`,
                attachTo: { element: '.mailbox-protocol-container', on: 'left' },
                buttons: [ doneButton ],
                when: {
                    "before-show": () => {
                        setShowProtocol(true);
                    },
                }

            }
        ];
        tour.addSteps(mailclientSteps);
        return tour;
    }, [experiment.page]);

    let listeners = [];

    useEffect(() => {
        document.getElementById("mailbox-viewer").scrollTo(0, 0);
      }, [openEmail]);

    const roleplayDescription = <></>

    const handleListClick = (event, email) => {
        setMailList(mailList.map((mail) => {
            if (mail._id === email._id) {
                mail.read = true;
                mail.selected = true;
            }
            else {
                mail.selected = false;
            }
            return mail;
        }));
        setOpenEmail(email);
    };

    function handleProcessedEmail() {}

    const handleLeave = () => {};

    const handleTimeout = () => {};

    const handleMarkUnread = () => {};

    const handleArchive = () => {};

    const handleSpamReport = () => {};

    const handleHandledEmailConfirm = () => {};

    const handleSpamReportConfirm = () => {};

    const handleRoleplayDialog = () => {};

    const displayTutorial = () => {};

    const displayProtocol = () => {};

    const showTour = () => {}

    // Tutorial states
    // 1. Tutorial not started (coming from BG) - start it
    if (experiment.tutorialStatus === TutorialStatus.NOT_STARTED) {
        logEvent(participant._id, "tutorial_start", {});
        handleListClick(null, mailList[0]);
        setExperiment({ ...experiment, tutorialStatus: TutorialStatus.STARTED_FIRST });
        tour.start();
    }
    // safeguard - if tutorial got closed we reopen it
    else if (experiment.tutorialStatus === TutorialStatus.STARTED_FIRST) {
        if (!tour.isActive()) {
            tour.start();
        }
    }   
    // 2. Done with first part - decide whether to show attention tutorial or not; set second tutorial to start
    else if (experiment.tutorialStatus === TutorialStatus.COMPLETED_FIRST) {
        if (participant.group !== "control") {
            let challengeId = null;
            let failureId = null;
            if (participant.group === "baseline") {
                challengeId = ChallengeTypes.PASSIVE_CONFIRM;
            }
            else if (participant.group === "reorder") {
                challengeId = ChallengeTypes.REALIGN_DOMAIN;
            }
            else {
                challengeId = ChallengeTypes.SELECT_DOMAIN;
                failureId = FailureTypes.SHOW;
            }
            setExperiment({ ...experiment, clickedUrl: mailList[1].url.url, challengeId: challengeId, failureId: failureId, page: Pages.ATTENTION_DEMO, tutorialStatus: TutorialStatus.SHOULD_START_SECOND });
        }
        else {
            setExperiment({ ...experiment, tutorialStatus: TutorialStatus.SHOULD_START_SECOND });
        }
    }
    // 3. Either we're back from the mechanism or we're coming from the first tutorial - start the second tutorial
    else if (experiment.tutorialStatus === TutorialStatus.SHOULD_START_SECOND) {
        setExperiment({ ...experiment, mechanism: MechanismStatus.NOT_FROM_MECHANISM, tutorialStatus: TutorialStatus.STARTED_SECOND });

        setTimeout( tour2.start(), 30);
    }
    // safeguard - if tutorial got closed we reopen it
    else if (experiment.tutorialStatus === TutorialStatus.STARTED_SECOND) {
        if (!tour2.isActive()) {
            setTimeout( () => tour2.start(), 30);
        }
    }

    return (
        <Box className="mailbox-container">
            <Box className="mailbox-header">
                <MailboxHeader name={company.name} logo={company.logo}>

                    <Button onClick={() => handleRoleplayDialog()} color='inherit' className={"profile-picture"}>
                        <ProfilePicture employee={employee} company={company} />
                    </Button>

                </MailboxHeader>
            </Box>
            <Box className="mailbox-buttons">
                <MailboxButtons>
                    <MailboxButton image={<MarkEmailUnread fontSize='large' />} callback={handleMarkUnread} disabled={!openEmail}>
                        Mark Unread
                    </MailboxButton>
                    <MailboxButton image={<CheckBox fontSize='large' />} callback={handleArchive} disabled={!openEmail || openEmail.url !== null} className={"mailbox-archive"}>
                        Mark as Completed
                    </MailboxButton>
                    <MailboxButton image={<Phishing fontSize='large' />} callback={handleSpamReport} disabled={!openEmail} className={"mailbox-tutorial-5"}>
                        Report Suspicious
                    </MailboxButton>
                    <MailboxButton image={<Help fontSize='large' />} callback={displayProtocol} className={"mailbox-tutorial-7"}>
                        Help
                    </MailboxButton>
                </MailboxButtons>
            </Box>
            <Box className="mailbox-body">
                <Box className="mailbox-list-container">
                    <Typography variant='h5' sx={{ px: "1rem" }}>
                        Inbox
                    </Typography>
                    <Box className="mailbox-list mailbox-tutorial-1" >
                        <EmailsList emails={mailList} clickHandler={handleListClick} />
                    </Box>
                </Box>
                <Box className="mailbox-viewer-container">
                    <Box className="mailbox-viewer" id="mailbox-viewer">
                    {openEmail ?
                        <EmailViewer email={openEmail} listeners={listeners} /> :
                        <SelectEmail />
                    }
                    </Box>
                </Box>
                <Box className="mailbox-protocol-container">
                    <Paper className='mailbox-protocol' elevation={3} sx={{width: "100%", height: "100%"}}>

                        <IconButton onClick={() => setProtocolOpen(!protocolOpen)}>
                        {protocolOpen ? <ChevronRight /> : <ChevronLeft />}
                        </IconButton>
                    <Collapse in={protocolOpen} orientation='horizontal'>
                        { showProtocol ? studyProtocol: <></>}
                    </Collapse>
                    </Paper>
                </Box>
            </Box>
        </Box>

    );
}