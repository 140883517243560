import { Stack } from '@mui/material';
import { BorderLinearProgress } from '../base/components';


export default function TaskProgress({ progress, total, text=true, progressBar=true, ...props }) {

    return (
        <Stack spacing={1}>
            {text && <span>{progress} of {total} completed</span>}
            {progressBar && <BorderLinearProgress value={(progress / total) * 100} variant='determinate' />}
        </Stack>
    );
};
