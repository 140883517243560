import { useState } from 'react';
import { Button, Box, Typography, Container, Grid, Item } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import { BACKEND_ADDRESS } from '../App';
import QuestionnaireRadio from '../components/framework/Questionnaire/QuestionnaireRadio';
import Likert from '../components/framework/Questionnaire/QuestionnaireLikert';
import QuestionnaireGender from '../components/framework/Questionnaire/QuestionnaireGender';
import { useLocalStorage } from "@uidotdev/usehooks";
import QuestionnaireGroup from '../components/framework/Questionnaire/QuestionnaireGroup';
import { Title } from '../components/base/typography';
import { Pages } from '../Types';


let known_services = {
    paypal: 0,
    stripe: 0,
    facebook: 0,
    instagram: 0,
    tiktok: 0,
    twitter: 0,
    ups: 0,
    dhl: 0,
    googleaccount: 0,
    icloud: 0
}

export default function PreQ() {

    const [formValues, setFormValues] = useState({});
    const [participant, setParticipant] = useLocalStorage("participant", {});
    const [experiment, setExperiment] = useLocalStorage("experimentStatus", {});

    const handleClickConfirm = (event) => {

        fetch(BACKEND_ADDRESS + "/answers",
            {
                method: 'POST',
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify({
                    'participant_id': participant._id,
                    'form_name': 'onboarding',
                    'form_data': { ...formValues, known_services }
                })
            }
        )
            .then(async (res) => {
                let result = await res.json();
                setExperiment({ ...experiment, page: Pages.BACKGROUND });
            })
            .catch((err) => console.log(err));
    };

    const handleFormValueChange = (event) => {
        setFormValues({
            ...formValues,
            [event.target.name]: event.target.value,
        });
    };

    const handleCheckboxChange = (event) => {

        if (known_services[event.target.value] == 1) {
            known_services[event.target.value] = 0;
        } else {
            known_services[event.target.value] = 1;
        }
    };

    return (
        <Container>
            <Title>
                Welcome!
            </Title>

            <Typography>
                Thanks for participating in our study! <br />
                First, we'd like to ask you some basic demographic information: <b>please fill this form</b> before continuing.
            </Typography>

            <QuestionnaireGroup onChange={handleFormValueChange}>

                <QuestionnaireGender />

                <QuestionnaireRadio
                    label="Age"
                    values={[
                        { label: "18-20", value: "18-20" },
                        { label: "21-30", value: "21-30" },
                        { label: "31-40", value: "31-40" },
                        { label: "41-50", value: "41-50" },
                        { label: "51-60", value: "51-60" },
                        { label: "61+", value: "61+" }
                    ]}
                    name="age" />

                <QuestionnaireRadio
                    label="Highest level of education achieved"
                    values={[
                        { label: "High school", value: "High school" },
                        { label: "Bsc.", value: "Bsc." },
                        { label: "Msc.", value: "Msc." },
                        { label: "PhD", value: "PhD" }
                    ]}
                    name="education" />

                <QuestionnaireRadio
                    label="I have a private email account"
                    values={[
                        { label: "Yes", value: "yes" },
                        { label: "No", value: "no" }
                    ]}
                    noAnswer={false}
                    name="personal_email" />

                <QuestionnaireRadio
                    label="I have a work email account"
                    values={[
                        { label: "Yes", value: "yes" },
                        { label: "No", value: "no" }
                    ]}
                    noAnswer={false}
                    name="work_email" />
            </QuestionnaireGroup>
            
            <br />
            <Typography>
                In the following, reply by selecting a value from 1 to 5, where 1 corresponds to "Rarely" or "Never" and 5 corresponds to "All the time" or "Always". <br />
                <b>How often during your day do you use...</b>
            </Typography>

            <QuestionnaireGroup onChange={handleFormValueChange}>
                <Likert
                    label="Desktop / Laptop computers"
                    name="computer_use" />

                <Likert
                    label="Smartphones"
                    name="smartphone_use" />

                <Likert
                    label="Email"
                    name="email_use" />

                <Likert
                    label="Instant messaging (e.g., SMS, WhatsApp, Signal, iMessage, ...)"
                    name="messaging_use" />

                <Likert
                    label="Other communications means (e.g., Teams, Slack, Zoom, Webex)"
                    name="job_communication_use" />

                <Likert
                    label="Computers for non-technical tasks (Office, data entry, ...)"
                    name="job_computer_use" />

                <Likert
                    label="Computers for technical tasks (Programming, IT, ...)"
                    name="job_technical_computer_use" />


            </QuestionnaireGroup>
            
            <br />
            <Typography>
                Finally, we'd like to ask you some background information about your <b>cybersecurity and online safety knowledge</b> and your past experiences with online scams.
            </Typography>

            <QuestionnaireGroup onChange={handleFormValueChange}>
                <QuestionnaireRadio
                    label="I am familiar with online email scams"
                    values={[
                        { label: "1 (No familiarity)", value: "1" },
                        { label: "2", value: "2" },
                        { label: "3", value: "3" },
                        { label: "4", value: "4" },
                        { label: "5 (Expert)", value: "5" }
                    ]}
                    noAnswer={true}
                    name="scam_familiarity" />

                <QuestionnaireRadio
                    label="I am familiar with the term phishing"
                    values={[
                        { label: "1 (No familiarity)", value: "1" },
                        { label: "2", value: "2" },
                        { label: "3", value: "3" },
                        { label: "4", value: "4" },
                        { label: "5 (Expert)", value: "5" }
                    ]}
                    noAnswer={true}
                    name="phishing_familiarity" />

                <QuestionnaireRadio
                    label="I received at least one email scam in my personal emails in the last six months"
                    values={[
                        { label: "No", value: "no" },
                        { label: "One", value: "one" },
                        { label: "More than one", value: "more than one" },
                        { label: "Don't recall", value: "don't recall" }
                    ]}
                    noAnswer={true}
                    name="received_phishing_personal" />

                <QuestionnaireRadio
                    label="I fell for an email scam in the last year"
                    values={[
                        { label: "No", value: "no" },
                        { label: "Almost", value: "almost" },
                        { label: "Yes", value: "yes" },
                        { label: "Don't recall", value: "don't recall" }
                    ]}
                    noAnswer={true}
                    name="fell_for_scam" />

                <QuestionnaireRadio
                    label="I received at least one email scam in my work emails in the last three months"
                    values={[
                        { label: "No", value: "no" },
                        { label: "One", value: "one" },
                        { label: "More than one", value: "more than one" },
                        { label: "Don't recall", value: "don't recall" }
                    ]}
                    noAnswer={true}
                    name="received_phishing_work" />

                <QuestionnaireRadio
                    label="My job trains me regularly on email security"
                    values={[
                        { label: "No", value: "no" },
                        { label: "Only sporadically or once", value: "sporadically" },
                        { label: "Yes", value: "yes" },
                        { label: "I don't use emails in my job", value: "don't use emails" }
                    ]}
                    noAnswer={true}
                    name="job_training" />
            </QuestionnaireGroup>
            
            <br />
            <Typography>
                <b>Which of these online services have you used?</b> You can select as many as you'd like.
            </Typography>

            <FormLabel id="familiar-services-checkbox" sx={{ paddingTop: '20px', lineHeight: '2.4rem' }}>I'm familiar with...</FormLabel>
            <FormGroup onChange={handleCheckboxChange} aria-labelledby="familiar-services-checkbox" name="known_services" row>
                <FormControlLabel control={<Checkbox />} label="Facebook" value="facebook" />
                <FormControlLabel control={<Checkbox />} label="Instagram" value="instagram" />
                <FormControlLabel control={<Checkbox />} label="X (Twitter)" value="twitter" />
                <FormControlLabel control={<Checkbox />} label="UPS" value="ups" />
                <FormControlLabel control={<Checkbox />} label="DHL" value="dhl" />
                <FormControlLabel control={<Checkbox />} label="FedEx" value="fedex" />
                <FormControlLabel control={<Checkbox />} label="Google Drive" value="googledrive" />
                <FormControlLabel control={<Checkbox />} label="Onedrive / Sharepoint" value="onedrive" />
                <FormControlLabel control={<Checkbox />} label="Microsoft Teams" value="teams" />
                <FormControlLabel control={<Checkbox />} label="Slack" value="slack" />
                <FormControlLabel control={<Checkbox />} label="PayPal" value="paypal" />
                <FormControlLabel control={<Checkbox />} label="Stripe" value="stripe" />
            </FormGroup>
            <Box py={2}></Box>

            <Box py={2}></Box>
            <Button variant="contained" className="Button" onClick={handleClickConfirm}>
                Continue
            </Button>
            <p>&nbsp;</p>


        </Container>
    );
}