import React from 'react';
import { ChallengeTypes, baseExperimentStatus } from '../../Types';
import { Box, Typography, Container, Alert, AlertTitle } from '@mui/material'
import { colorDifference, formatURL, levenshteinDistance } from '../../utils';
import { useLocalStorage } from '@uidotdev/usehooks';
import { htmlDecode } from '../../utils';
import AttentionButtons from './elements/AttentionButtons';

export default function Failure({failureElement, proceedHandler, goBackHandler, reportHandler, ...props}) {

    const [experiment, setExperiment] = useLocalStorage("experimentStatus", baseExperimentStatus);

    return (
        <Box textAlign="center" padding={"0 2rem 2rem"}>

            {/* <Alert severity="error" sx={{marginBottom: "2rem"}}>
                <AlertTitle><b>Wrong answer - be careful!</b></AlertTitle>
                You might be victim of a phishing attempt!
            </Alert> */}

            <Box marginBottom={"2rem"}>
            {failureElement}
            </Box>

            <AttentionButtons solved={true} proceedHandler={proceedHandler} goBackHandler={goBackHandler} reportHandler={reportHandler} />

        </Box>
    )
}
