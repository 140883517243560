import CssBaseline from '@mui/material/CssBaseline';

import { BrowserRouter, Routes, Route } from "react-router-dom";

import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theme';

import { baseExperimentStatus, Pages } from './Types';

// React Hooks
import { useLocalStorage } from '@uidotdev/usehooks';

// Pages
import ConsentShort from "./pages/ConsentShort";
import PreQ from "./pages/PreQ";
import MailClient from "./pages/MailClient"
import PostQ from "./pages/PostQ";
import Background from "./pages/Background";
import Debriefing from "./pages/Debriefing";
import Reward from "./pages/Reward";
import Attention from './pages/Attention';
import ConsentFull from './pages/ConsentFull';
import { useEffect, useMemo } from 'react';
import { UAParser } from 'ua-parser-js';
import Mobile from './pages/Mobile';
import MailClientTutorial from './pages/MailClientTutorial';
import AttentionDemo from './pages/AttentionDemo';

export const BACKEND_ADDRESS = process.env.REACT_APP_BACKEND_ADDRESS || process.env.REACT_APP_BACKEND_LOCAL


export default function App() {

  const [participant, setParticipant] = useLocalStorage("participant", {});
  const [experiment, setExperiment] = useLocalStorage("experimentStatus", baseExperimentStatus);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [experiment.page]);

  let isMobile = useMemo(() => {
    const parser = new UAParser();
    return parser.getDevice().type;
  }, []);

  useEffect(() => {
    // get the URL paramenter PLATFORM_ID
    const urlParams = new URLSearchParams(window.location.search);
    const platformId = urlParams.get('PLATFORM_ID');
    if (platformId) {
      setParticipant({ ...participant, platformId: platformId });
    }
    else {
      setParticipant({ ...participant, platformId: null });
    }
  }, []);

  if (isMobile) {
    return (
      <CssBaseline>
        <ThemeProvider theme={theme}>
          <Mobile />
        </ThemeProvider>
      </CssBaseline>
    );
  }
  else {
    return (
      <CssBaseline>
        <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={
              <>
                { !isMobile && <>
                  { experiment.page === Pages.CONSENT_SHORT && <ConsentShort /> }
                  { experiment.page === Pages.PRE_QUESTIONNAIRE && <PreQ /> }
                  { experiment.page === Pages.BACKGROUND && <Background /> }
                  { experiment.page === Pages.MAILBOX_DEMO && <MailClientTutorial /> }
                  { experiment.page === Pages.ATTENTION_DEMO && <AttentionDemo />}
                  { experiment.page === Pages.MAILBOX && <MailClient /> }
                  { experiment.page === Pages.ATTENTION && <Attention /> }
                  { experiment.page === Pages.DEBRIEFING && <Debriefing /> }
                  { experiment.page === Pages.POST_QUESTIONNAIRE && <PostQ /> }
                  { experiment.page === Pages.REWARD && <Reward /> }
                </>}
                { isMobile && <Mobile /> }
              </>
            } />
            <Route path="consent-full" element={<ConsentFull />} />
          </Routes>
        </BrowserRouter>
        </ThemeProvider>
      </CssBaseline>
    );
  }
}