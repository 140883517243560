import { List } from '@mui/material'
import { EmailHeader } from '../base/typography'

export default function RecipientLong({email, ...props}) {

    function formatAddressList(addressList) {
        return addressList.map((address) =>
        address.display_name ?
            `"${address.display_name}" <${address.address}>` :
            address.address
        ).join(", ");
    }

    return (
        <List dense disablePadding >
            <EmailHeader>
                To: {formatAddressList(email.recipients)}
            </EmailHeader>
            {email.cc.length > 0 && <EmailHeader>
                CC: {formatAddressList(email.cc)}
            </EmailHeader>}
            {email.bcc.length > 0 && <EmailHeader>
                BCC: {formatAddressList(email.bcc)}
            </EmailHeader>}
        </List>
    )
}