import { useLocalStorage } from '@uidotdev/usehooks';

export const ChallengeTypes = {
    GENERIC: -1,
    AUTO_DOMAIN: 0,
    CLICK_DOMAIN: 1,
    SELECT_DOMAIN: 2,
    TYPE_DOMAIN: 3,
    PASSIVE_CONFIRM: 4,
    REALIGN_DOMAIN: 5,
};

export const FailureTypes = {
    SHOW: 0,
    IMPERSONATION: 1,
    DIFFERENCE: 2,
}

export const ExperimentProgress = {
    NOT_STARTED: 0,
    STARTED: 1,
    OVER: 2,
    LEFT_EARLY: 3,
    TIMEOUT: 4,
};

export const MechanismStatus = {
    NOT_FROM_MECHANISM: 0,
    VISITED: 1,
    ABANDONED: 2,
    REPORTED: 3,
};

export const Pages = {
    CONSENT_SHORT: 0,
    CONSENT_LONG: 1,
    PRE_QUESTIONNAIRE: 2,
    BACKGROUND: 3,
    MAILBOX: 4,
    ATTENTION: 5,
    DEBRIEFING: 6,
    POST_QUESTIONNAIRE: 7,
    REWARD: 8,
    MAILBOX_DEMO: 9,
    ATTENTION_DEMO: 10,
}

export const TutorialStatus = {
    NOT_STARTED: 0,
    STARTED_FIRST: 1,
    COMPLETED_FIRST: 2,
    SHOULD_START_SECOND: 3,
    STARTED_SECOND: 4,
    COMPLETED: 5,
}


export const baseExperimentStatus = {
    "page": Pages.CONSENT_SHORT,
    "status": ExperimentProgress.NOT_STARTED,
    "mechanism": MechanismStatus.NOT_FROM_MECHANISM,
    "clickedUrl": "",
    "challengeId": ChallengeTypes.AUTO_DOMAIN,
    "failureId": FailureTypes.SHOW,
    "mailboxOnboarded": false,
    "mechanismOnboarded": false,
    "sawChallenges": [],
    "tutorialStatus": TutorialStatus.NOT_STARTED,
};
