import { Box, Container } from '@mui/material';
import AttentionButtons from './elements/AttentionButtons';


export default function Challenge({challenge, challengeElement, solved, proceedHandler, goBackHandler, reportHandler, ...props}) {

    return (
        <Box textAlign="center" paddingBottom={"2rem"}>
            <Container sx={{marginBottom: "1rem"}} className='attention-tutorial-task'>
                {challengeElement}
            </Container>

            <AttentionButtons solved={solved} proceedHandler={proceedHandler} goBackHandler={goBackHandler} reportHandler={reportHandler} />
        </Box>
    )


}
